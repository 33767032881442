<template>
    <div class="wgcna">
      <div class="wgcna-bg">
        <p class="wgcna-bg-title">miRNA Target Genes Search</p>
        <p></p>

        <a-auto-complete v-model="searchValue" :data-source="dataSource" :filterOption="onInput" :defaultOpen="false">
          <a-input allow-clear class="wgcna-search" placeholder="Example： bta-miR-26a">
            <a-icon slot="prefix" style="font-size: 30px;color:#cacaca;" type="search"/>
            <div slot="suffix">
              <a-button class="wgcna-search-btn" type="primary" shape="round" @click="handleSearch">search
              </a-button>
            </div>
          </a-input>
        </a-auto-complete>
      </div>
    </div>
</template>

<script>
import {fetchMiRNATargetList} from "@/request/niu_api";

export default {
  name:"miRNATargetSearch",
  data(){
    return {
      searchValue: "",
      dataSource: []
    }
  },
  mounted() {
    fetchMiRNATargetList().then(res=>{
      this.dataSource = res.data;
    })
  },
  methods:{
    onInput(searchText,option){
      return option.key.toUpperCase().indexOf(searchText.toUpperCase()) !== -1;
    },
    handleSearch(){
      this.$router.push({path:"/miRNA_target_search_result",query:{searchValue:this.searchValue}})
    }
  }
}
</script>

<style scoped lang="scss">
.wgcna {
  padding: 1.3rem 8rem;
  width: 100vw;
  min-height: calc(100vh - 5rem - 64px);

  &-bg {
    width: 100%;
    height: calc(100vh - 7.8rem - 64px);
    background-image: url("/images/co/searchBack.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 19px;
      color: #666;
    }

    &-title {
      font-size: 27px !important;
      font-weight: 600;
      color: #333333;
      line-height: 31px;
    }
  }

  &-search {
    width: 40rem;
    height: 60px;

    ::v-deep .ant-input {
      height: 60px;
      border-radius: 30px;
      padding-left: 50px;
      padding-right: 120px;
      font-size: 18px;
    }

    &-btn {
      background: #097F35;
      border: 0;
      height: 52px;
      width: 106px;
      position: relative;
      left: 6px;
      font-size: 16px;
    }

    ::v-deep .ant-input-clear-icon {
      font-size: 20px;
    }
  }
}

::v-deep .ant-select-focused .ant-select-selection,::v-deep .ant-select-selection:focus, ::v-deep .ant-select-selection:active{
  border:0 !important;
}
</style>